<template>
  <a-layout>
    <a-layout-sider width="270" :style="{ height: '100vh', position: 'fixed', left: 0 }">
      <left-top-logo />
      <ul class="side-menu-wrapper">
        <li
          v-for="(nav, index) in navList"
          :key="index"
          @click="currentClick(index)"
          class="menu-list"
        >
          <div :class="{'active-title': activeIndex === index}" class="iotplt-cursor-pointer sub-menu" @click="pageJump(nav.id)">{{ nav.title }}</div>
          <div
            v-if="nav.children.length > 0 && activeIndex === index"
            class="menu-children-list"
          >
            <ul class="nav-list">
              <li
                v-for="(item, idx) in nav.children"
                :key="idx"
                @click.stop="childrenCurrentClick(idx)"
                class="menu-list"
                :class="{'active-li': childrenActiveIndex === idx}"
              >
                <div :class="{'active-title': childrenActiveIndex === idx}" class="iotplt-cursor-pointer menu-item" @click="pageJump(item.id)">
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: '270px' }">
      <a-layout-content :style="{ marginLeft: '10px' }">
        <div class="markdown-body day light-scheme" v-html="content" @scroll="docsScroll" ref="apiDoc" style="overflow: scroll; height: calc(100vh);" />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import LeftTopLogo from '@/components/Logo'
// 其他元素使用 github 的样式
import 'github-markdown-css'
// 代码高亮
import hljs from 'highlight.js'
import 'highlight.js/styles/atelier-forest-light.css'
import { findPublicApiV1Doc } from '@/api/doc'

export default {
  components: { LeftTopLogo },
  data() {
    return {
      navList: [],
      activeIndex: 0,
      docsFirstLevels: [],
      docsSecondLevels: [],
      childrenActiveIndex: 0,
      content: ''
    }
  },
  async mounted() {
    await findPublicApiV1Doc().then((data) => {
      this.content = data
    })
    this.highlightCode()
    this.navList = this.handleNavTree()
    this.getDocsFirstLevels()
  },
  methods: {
    highlightCode() {
      const preEl = document.querySelectorAll('pre')
      preEl.forEach((el) => {
        hljs.highlightElement(el)
      })
    },

    // 设置二级目录index
    childrenCurrentClick(index) {
      this.childrenActiveIndex = index
    },

    // 获取一级目录每个菜单到顶部距离的数组
    getDocsFirstLevels() {
      const firstLevels = []
      Array.from(document.querySelectorAll('h1'), element => {
        firstLevels.push(element.offsetTop - 20)
      })
      this.docsFirstLevels = firstLevels
    },

    // 获取二级目录 每个菜单到顶部距离的数组
    getDocsSecondLevels(parentActiveIndex) {
      let secondLevels = []
      this.childrenActiveIndex = 0
      const navChildren = this.navList[parentActiveIndex].children
      if (navChildren.length > 0) {
        secondLevels = navChildren.map((item) => {
          return this.$el.querySelector(`#${item.id}`).offsetTop - 20
        })
        this.docsSecondLevels = secondLevels
      }
    },

    // 滚动事件 自动高亮选中对应菜单
    docsScroll() {
      if (this.titleClickScroll) {
        return
      }

      const scrollTop = this.$refs.apiDoc.scrollTop
      const firstLevelIndex = this.getLevelActiveIndex(scrollTop, this.docsFirstLevels)
      this.currentClick(firstLevelIndex)
      const secondLevelIndex = this.getLevelActiveIndex(scrollTop, this.docsSecondLevels)
      this.childrenCurrentClick(secondLevelIndex)
    },

    // 滚动事件中 获取当前菜单的index值
    getLevelActiveIndex(scrollTop, docsLevels) {
      let currentIdx = null
      const nowActive = docsLevels.some((currentValue, index) => {
        if (currentValue >= scrollTop) {
          currentIdx = index
          return true
        }
      })

      currentIdx = currentIdx - 1
      if (nowActive && currentIdx === -1) {
        currentIdx = 0
      } else if (!nowActive && currentIdx === -1) {
        currentIdx = docsLevels.length - 1
      }

      return currentIdx
    },

    // 菜单点击事件 跳转到对应的位置 offsetTop
    pageJump(id) {
      this.$nextTick(() => {
        this.titleClickScroll = true
        const top = this.$el.querySelector(`#${id}`).offsetTop - 20
        this.$refs.apiDoc.scrollTop = top
      })

      // 点击事件设置offsetTop的滚动不需要触发滚动事件 所以设置一个100的延时
      setTimeout(() => { this.titleClickScroll = false }, 100)
    },

    // 点击事件 设置当前激活的一级菜单的index
    currentClick(index) {
      this.activeIndex = index
      this.getDocsSecondLevels(index)
    },

    // 获取页面显示的title值 level id等
    getTitle(content) {
      let nav = []
      const tempArr = []
      content.replace(/<h([1-2])(.*?)>(.*?)<\/h([1-2])\>/g, (match, m1, m2) => {
        const title = match.replace('\n', '')
        const level = parseInt(m1)
        tempArr.push({
          title: title.replace(/^<h([1-2])(.*?)>/, '').replace(/<\/h([1-2])\>/, ''),
          level: level,
          id: this.$lodash.trim(m2.replace(/id="/, '').replace(/"/, '')),
          children: []
        })
      })

      // 只处理一级二级标题，以及添加与id对应的index值
      nav = tempArr.filter(item => item.level <= 2)
      let index = 0
      return nav.map(item => {
        item.index = index++
        return item
      })
    },

    // 将一级二级标题数据处理成树结构
    handleNavTree() {
      const navs = this.getTitle(this.content)
      const navLevel = [1, 2]
      let retNavs = []
      let toAppendNavList

      navLevel.forEach(level => {
        // 遍历一级二级标题，将同一级的标题组成新数组
        toAppendNavList = navs.filter(item => {
          return level === item.level
        })

        if (retNavs.length === 0) {
          // 处理一级标题 添加到retNavs中
          retNavs = retNavs.concat(toAppendNavList)
        } else {
          // 处理二级标题，并将二级标题添加到对应的父级标题的children中
          toAppendNavList.forEach(item => {
            const parentNavIndex = this.getParentIndex(navs, item.index)
            return this.appendToParentNav(retNavs, parentNavIndex, item)
          })
        }
      })
      return retNavs
    },

    // 获取二级菜单的父菜单在数组navs中的索引值
    getParentIndex(nav, endIndex) {
      for (var i = endIndex - 1; i >= 0; i--) {
        if (nav[endIndex].level > nav[i].level) {
          return nav[i].index
        }
      }
    },

    // 将二级菜单添加到一级菜单的children下
    appendToParentNav(nav, parentIndex, newNav) {
      const index = nav.findIndex((item) => {
        return parentIndex === item.index
      })
      nav[index].children = nav[index].children.concat(newNav)
    }
  }
}
</script>

<style lang="less" scoped>
.side-menu-wrapper {
  border-right-color: transparent;
  height: calc(100vh);

  overflow: auto;
  -ms-overflow-style: none;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
  }

  .menu-list {
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 40px;
    margin-top: 4px;
    width: 100%;

    .sub-menu {
      height: 100%;
      padding-left: 24px;
    }

    .menu-item {
      height: 100%;
      padding-left: 30px;

      &:hover {
        color: #7958e3;
      }
    }
  }
}

.active-title {
  color: #7958e3;
}

.active-li {
  background-color: #f6f0ff;
}

ul {
  padding: 2px 6px;
}
li {
  margin: 2px 6px;
}

.day   { background: #fff; color: #707070; }
.night { background: #fff; color: white; }

@media (prefers-color-scheme: dark) {
  .day.dark-scheme   { background:  #fff; color: white; }
  .night.dark-scheme { background: #fff; color:  #ddd; }
}

@media (prefers-color-scheme: light) {
  .day.light-scheme   { background: #fff; color:  #555; }
  .night.light-scheme { background:  #fff; color: black; }
}

.day, .night {
  vertical-align: middle;
}

.markdown-body {
  padding: 20px;
  font-size: 14px;
}
</style>
